import type React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { type PaymentProductSchema } from '~shared/api/client';
import { CheckIcon, TelegramStarIcon } from '~shared/assets/icons';
import { isDefined } from '~shared/lib';
import { Button, Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle, Skeleton } from '~shared/ui';

export const PurchaseGameLivesDrawer: React.FC = observer(() => {
  const {
    projectStore: { project },
    userStore: { user, getUser, isPurchaseGameLivesDrawerOpen, closePurchaseGameLivesDrawer },
    boostsStore: { boosts, getBoosts, purchaseBoost },
  } = useStore();

  const [loadingId, setLoadingId] = useState<string | null>(null);

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        closePurchaseGameLivesDrawer();
        setLoadingId(null);
      }
    },
    [closePurchaseGameLivesDrawer]
  );

  const purchaseCallback = useCallback(async () => {
    await getUser();
  }, [getUser]);

  const handlePurchase = useCallback(
    async (boost: PaymentProductSchema) => {
      try {
        if (isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id)) return;
        setLoadingId(boost.id);
        closePurchaseGameLivesDrawer();
        await purchaseBoost(
          user.id,
          project.id,
          { id: boost.id, title: boost.title, description: boost.description },
          purchaseCallback
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingId(null);
      }
    },
    [purchaseBoost, closePurchaseGameLivesDrawer, purchaseCallback, user, project]
  );

  useEffect(() => {
    if (isDefined(user) && isDefined(user.id) && isDefined(project) && isDefined(project.id)) {
      void getBoosts(user.id, project.id);
    }
  }, [getBoosts, user, project]);

  return (
    <Drawer open={isPurchaseGameLivesDrawerOpen} onOpenChange={handleOpenChange}>
      <DrawerContent>
        <DrawerHeader className="space-y-4">
          <DrawerTitle>Lives</DrawerTitle>
          <DrawerDescription>You can purchase more lives</DrawerDescription>
        </DrawerHeader>

        <div className="flex flex-col space-y-2">
          {isNil(boosts?.lives) &&
            Array.from({ length: 5 }).map((_, index) => <Skeleton className="h-20 w-full" key={index} />)}
          {boosts?.lives.map((boost) => (
            <div
              className="flex items-center justify-between rounded-xl bg-white/10 p-3 backdrop-blur-lg"
              key={boost.id}
            >
              <div className="flex flex-col space-y-2">
                <span className="font-bold">{boost.amount} lives</span>
                <div className="flex items-center">
                  For <TelegramStarIcon className="mx-1 size-5 text-blue" /> <b>{boost.price}</b>
                </div>
              </div>

              {isDefined(boost.can_buy) && boost.can_buy ? (
                <Button
                  className="ml-auto min-w-[120px] rounded-xl px-5 py-2 text-sm font-normal"
                  buttonColor="blue"
                  isLoading={loadingId === boost.id}
                  disabled={isDefined(loadingId)}
                  onClick={() => {
                    void handlePurchase(boost);
                  }}
                >
                  Buy
                </Button>
              ) : (
                <Button
                  variant="wrapper"
                  size="wrapper"
                  className="ml-auto flex min-w-[120px] items-center rounded-xl border border-[#8FF3D01A] px-5 py-2 text-sm text-mint"
                >
                  <CheckIcon className="mr-2 size-[18px]" />
                  <span>Done</span>
                </Button>
              )}
            </div>
          ))}
        </div>
      </DrawerContent>
    </Drawer>
  );
});
