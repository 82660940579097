import { makeAutoObservable } from 'mobx';

import {
  type SubscribedToChannelResponse,
  type UserSchema,
  WebappAuthService,
  WidgetsUsersApiService,
} from '~shared/api/client';

export class UserStore {
  user: UserSchema | null;
  subscriptionStatus: SubscribedToChannelResponse | null;

  isSubscriptionStatusDrawerOpen: boolean;

  isShareDrawerOpen: boolean;

  isConnectWalletDrawerOpen: boolean;

  isPurchaseGameLivesDrawerOpen: boolean;

  hedgehogDashCompleteProps: { amount: number } | null;

  loaded: {
    user: boolean;
  };

  constructor() {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'userStore' });

    this.user = null;
    this.subscriptionStatus = null;

    this.isSubscriptionStatusDrawerOpen = false;
    this.isShareDrawerOpen = false;
    this.isConnectWalletDrawerOpen = false;
    this.isPurchaseGameLivesDrawerOpen = false;
    this.hedgehogDashCompleteProps = null;

    this.loaded = {
      user: false,
    };
  }

  private async getIpAddress(): Promise<{ ip: string } | undefined> {
    try {
      const response = await fetch('https://api64.ipify.org/?format=json');
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getUser(params: { withIp: boolean } = { withIp: false }): Promise<UserSchema | undefined> {
    try {
      const inviter: string | null = window.Telegram?.WebApp?.initDataUnsafe.start_param ?? null;
      const inviterId: number | null = inviter != null ? Number(inviter) : null;
      const ipAddressResponse = params.withIp ? await this.getIpAddress() : undefined;
      const userReponse = await WebappAuthService.loginWebappApiV2AuthWebappLoginGet(inviterId, ipAddressResponse?.ip);
      this.user = userReponse;
      this.loaded.user = true;
      return userReponse;
    } catch (error) {
      console.error(error);
    } finally {
      this.loaded.user = true;
    }
  }

  async getSubscriptionStatus(): Promise<SubscribedToChannelResponse | undefined> {
    try {
      const subscribedToChannelReponse = await WebappAuthService.subscribedToChannelApiV2AuthSubscribedToChannelGet();
      this.subscriptionStatus = subscribedToChannelReponse;
      return subscribedToChannelReponse;
    } catch (error) {
      console.error(error);
    }
  }

  async updateUserWallet(userId: string, wallet: string | null): Promise<UserSchema | undefined> {
    try {
      const userResponse = await WidgetsUsersApiService.updateTonWalletAddressApiV2WidgetUsersUserIdTonWalletPut(
        userId,
        { ton_wallet_address: wallet }
      );
      this.user = userResponse;
      return userResponse;
    } catch (error) {
      console.error(error);
    }
  }

  openSubscriptionCheckDrawer(): void {
    this.isSubscriptionStatusDrawerOpen = true;
  }

  closeSubscriptionCheckDrawer(): void {
    this.isSubscriptionStatusDrawerOpen = false;
  }

  openShareDrawer(): void {
    this.isShareDrawerOpen = true;
  }

  closeShareDrawer(): void {
    this.isShareDrawerOpen = false;
  }

  openConnectWalletDrawer(): void {
    this.isConnectWalletDrawerOpen = true;
  }

  closeConnectWalletDrawer(): void {
    this.isConnectWalletDrawerOpen = false;
  }

  openPurchaseGameLivesDrawer(): void {
    this.isPurchaseGameLivesDrawerOpen = true;
  }

  closePurchaseGameLivesDrawer(): void {
    this.isPurchaseGameLivesDrawerOpen = false;
  }

  openHedgehogDashCompleteDrawer(props: { amount: number }): void {
    this.hedgehogDashCompleteProps = props;
  }

  closeHedgehogDashCompleteDrawer(): void {
    this.hedgehogDashCompleteProps = null;
  }
}
