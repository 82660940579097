import { useEffect, useState } from 'react';

export type ScreenDimensions = {
  width: number;
  height: number;
};

export const useScreenDimensions = (): ScreenDimensions => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: window.innerWidth,
    height: Telegram.WebApp.viewportHeight,
  });

  useEffect(() => {
    const handleResize = (): void => {
      requestAnimationFrame(() => {
        setWindowSize({
          width: window.innerWidth,
          height: Telegram.WebApp.viewportHeight,
        });
      });
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ...windowSize };
};
