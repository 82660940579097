export enum AppRoutes {
  Root = '/',
  Projects = '/projects',
  Home = '/home',
  Tasks = '/tasks',
  Friends = '/friends',
  LevelFriends = '/friends/:level',
  Games = '/games',
  FortuneSpin = '/games/fortune-spin',
  HedgehogDash = '/games/hedgehog-dash',
  AirDrops = '/airdrops',
  TermsAndConditions = '/terms-and-conditions',
  Top = '/top',
  DailyBonuses = '/daily-bonuses',
  Boost = '/boost',
  Wallet = '/wallet',
}
