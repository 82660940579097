/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
export type DropSchema = {
  id: number;
  type?: DropSchema.type;
  qty?: number;
  speed: number;
  dropped_at: number;
};
export namespace DropSchema {
  export enum type {
    COIN = 'coin',
  }
}
