/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { CreateFishingResponse } from '../models/CreateFishingResponse';
import type { FinishFishingResponse } from '../models/FinishFishingResponse';
import type { FinishFishingSchema } from '../models/FinishFishingSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsFishingApiService {
  /**
   * Create Fishing Game
   * @param userId
   * @returns CreateFishingResponse Successful Response
   * @throws ApiError
   */
  public static createFishingGameApiV2WidgetUsersUserIdFishingPost(
    userId: string
  ): CancelablePromise<CreateFishingResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/users/{user_id}/fishing',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Finish Game
   * @param fishingId
   * @param userId
   * @param requestBody
   * @returns FinishFishingResponse Successful Response
   * @throws ApiError
   */
  public static finishGameApiV2WidgetUsersUserIdFishingFishingIdFinishPost(
    fishingId: string,
    userId: string,
    requestBody: FinishFishingSchema
  ): CancelablePromise<FinishFishingResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/users/{user_id}/fishing/{fishing_id}/finish',
      path: {
        fishing_id: fishingId,
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
