/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
export type FinishFishingResponse = {
  state?: FinishFishingResponse.state;
};
export namespace FinishFishingResponse {
  export enum state {
    SUCCESS = 'success',
    FAILED = 'failed',
  }
}
