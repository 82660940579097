import React, { useCallback } from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';

import { hedghogDashWalletImage } from '~shared/assets/images/hedgehog-dash';
import { isDefined } from '~shared/lib';
import {
  Amount,
  Button,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '~shared/ui';

export const HedgehogDashCompleteDrawer: React.FC = observer(() => {
  const {
    userStore: { hedgehogDashCompleteProps, closeHedgehogDashCompleteDrawer },
  } = useStore();

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        closeHedgehogDashCompleteDrawer();
      }
    },
    [closeHedgehogDashCompleteDrawer]
  );

  return (
    <Drawer open={isDefined(hedgehogDashCompleteProps)} onOpenChange={handleOpenChange} shouldScaleBackground>
      <DrawerContent className="flex flex-col">
        <DrawerHeader className="flex flex-col items-center space-y-4">
          <img className="h-auto w-20" src={hedghogDashWalletImage} alt="" />
          <DrawerTitle className="mt-8">Round complete!</DrawerTitle>
          <DrawerDescription className="text-white">Your reward is:</DrawerDescription>
        </DrawerHeader>

        <Amount
          className="mt-8 [&>*]:text-2xl [&>img]:mr-1 [&>img]:size-10"
          value={hedgehogDashCompleteProps?.amount.toString()}
        />

        <DrawerFooter className="mt-8">
          <Button buttonColor="blue" onClick={closeHedgehogDashCompleteDrawer}>
            Close
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
});
